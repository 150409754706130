<template>
    <div>
        <Container v-if="isshow" :mainpagedata="mainpagedata" :mydefault="mydefault"
            v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"
            v-on:getpageinfo="getPageinfoEvent"></Container>
    </div>
</template>

<script>
import Container from "@/components/Container.vue";
export default {
    name: "weipages",
    data() {
        return {
            mainpagedata: {
                //特殊页面
                showpages: {
                    //判断pages页面，常规通用组件页面显示pages，特殊页面显示special
                    isshowpages: false, //显示pages的内容
                    isshowspecial: true, //显示special的内容
                    mycontrol: 226, //当该页面为special页面内容时，用数字控制页面-详情内容显示
                    content: {}, //特殊页面中模块的配置数据
                },
            },
            mydefault: {
                myheadactive: "a10", //该字段为string类型，是头部导航key值
                myasideactive: "567", //该字段为string类型，是子页面的id
                asideid: 10, //该字段为int类型，是头部导航key值
            }, //本页面的导航默认值-逆向绑定（可优化-通过当前页面的path值，对数据进行筛选对比，将值自动赋值，避免修改路由之后出现页面&导航不对应状况）
            mytoken: localStorage.getItem("token"), //获取存储的token
            isshow: true, //用于判断页面模块是否存在
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        };
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            //加载loading
            this.mainpagedata.loading = true;
        }
    },

    components: {
        Container,
    },
};
</script>

<style>
</style>
